<template>
  <b-dropdown
    id="import-user-dropdown"
    class="mr-2"
    :text="$t('TABLES.PROJECT_USER_NAVS.IMPORT')"
    size="sm"
    variant="primary"
    right
  >
    <UserImportLive :segmentationBreakdown="segmentationBreakdown" />
    <UserImportFile />
  </b-dropdown>
</template>

<script>
import UserImportLive from "./user_import/LiveImport.vue";
import UserImportFile from "./user_import/FileImport.vue";

export default {
  name: "UserImport",

  props: {
    segmentationBreakdown: { type: Array, required: true }
  },

  components: {
    UserImportLive,
    UserImportFile
  }
};
</script>
