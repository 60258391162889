<template>
  <div>
    <div class="dialog-container mt-10 px-10 py-5">
      <div>
        <div class="d-flex justify-content-between">
          <h6>{{ $t("TABLES.PROJECT_USER_UPLOAD.UPDATE_SUMMARY") }}</h6>
          <a
            v-if="validationResponse.non_critical_errors > 0"
            href="javascript:void(0)"
            class="pb-2"
            @click="downloadErrorFile"
          >
            {{ $t("TABLES.PROJECT_USER_UPLOAD.VALIDATE_ERROR_FILE_DOWNLOAD") }}
          </a>
        </div>

        <div class="d-flex justify-content-between p-5 mt-5">
          <div class="w-100">
            <div class="row">
              <span class="col-9 text-right mb-1"
                >{{
                  $t("TABLES.PROJECT_USER_UPLOAD.UPDATE_PARTICIPANTS_ADDED")
                }}:</span
              >
              <span class="col-3 text-left">{{
                validationResponse.participants_created == null
                  ? 0
                  : validationResponse.participants_created
              }}</span>
            </div>

            <div class="row">
              <span class="col-9 text-right mb-1"
                >{{
                  $t("TABLES.PROJECT_USER_UPLOAD.UPDATE_PARTICIPANTS_UPDATED")
                }}:</span
              >
              <span class="col-3 text-left">{{
                validationResponse.participants_updated == null
                  ? 0
                  : validationResponse.participants_updated
              }}</span>
            </div>

            <div class="row">
              <span
                class="col-9 text-right mb-1"
                :class="{ 'text-danger' : validationResponse.participants_rejected > 0 }"  
              >
                {{ $t("TABLES.PROJECT_USER_UPLOAD.UPDATE_PARTICIPANTS_REJECTED") }}:
              </span>
              <span
                class="col-3 text-left"
                :class="{ 'text-danger' : validationResponse.participants_rejected > 0 }"  
              >
                {{ validationResponse.participants_rejected == null ? 0 : validationResponse.participants_rejected }}
              </span>
            </div>
          </div>

          <div class="w-100">
            <div class="row">
              <span class="col-9 text-right mb-1"
                >{{
                  $t("TABLES.PROJECT_USER_UPLOAD.UPDATE_SEGMENTATIONS_ADDED")
                }}:</span
              >
              <span class="col-3 text-left">{{
                validationResponse.segmentations_created == null
                  ? 0
                  : validationResponse.segmentations_created
              }}</span>
            </div>

            <div class="row">
              <span class="col-9 text-right mb-1"
                >{{
                  $t("TABLES.PROJECT_USER_UPLOAD.UPDATE_SEGMENTATIONS_UPDATED")
                }}:</span
              >
              <span class="col-3 text-left">{{
                validationResponse.segmentations_updated == null
                  ? 0
                  : validationResponse.segmentations_updated
              }}</span>
            </div>

            <div class="row">
              <span
                class="col-9 text-right mb-1"
                :class="{ 'text-danger' : validationResponse.segmentations_rejected > 0 }"  
              >
                {{ $t("TABLES.PROJECT_USER_UPLOAD.UPDATE_SEGMENTATIONS_REJECTED")}}:
              </span
              >
              <span
                class="col-3 text-left"
                :class="{ 'text-danger' : validationResponse.segmentations_rejected > 0 }"  
              >
                {{ validationResponse.segmentations_rejected == null ? 0 : validationResponse.segmentations_rejected }}
              </span>
            </div>
          </div>

          <div class="w-100">
            <div class="row">
              <span class="col-9 text-right mb-1"
                >{{
                  $t("TABLES.PROJECT_USER_UPLOAD.UPDATE_SEGMENTS_ADDED")
                }}:</span
              >
              <span class="col-3 text-left">{{
                validationResponse.segments_created == null
                  ? 0
                  : validationResponse.segments_created
              }}</span>
            </div>

            <div class="row">
              <span
                class="col-9 text-right mb-1"
                :class="{ 'text-danger' : validationResponse.segments_rejected > 0 }"  
              >
                {{ $t("TABLES.PROJECT_USER_UPLOAD.UPDATE_SEGMENTS_REJECTED") }}:
              </span
              >
              <span
                class="col-3 text-left"
                :class="{ 'text-danger' : validationResponse.segments_rejected > 0 }"  
              >
                {{ validationResponse.segments_rejected == null ? 0 : validationResponse.segments_rejected }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="progress bg-white">
        <div
          class="progress-bar"
          role="progressbar"
          :aria-valuenow="updateProgress"
          aria-valuemin="0"
          aria-valuemax="100"
          :style="`width: ${updateProgress}%`"
        />
      </div>
    </div>

    <div
      class="d-flex justify-content-between mt-5 px-2"
      v-if="validationResponse != null"
    >
      <div class="text-left">
        <!-- <a
          href="javascript:void(0)"
          @click="downloadErrorFile"
        >
          {{ $t('TABLES.PROJECT_USER_UPLOAD.UPDATE_ERROR_FILE_DOWNLOAD') }}
        </a> -->
      </div>

      <button
        type="button"
        class="btn btn-sm btn-primary"
        :disabled="validationResponse.status != 2"
        @click="$emit('on-close')"
      >
        {{ $t("TABLES.PROJECT_USER_UPLOAD.UPDATE_CLOSE") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "FileImportUpdate",

  props: {
    validationResponse: { type: Object, required: true },
  },

  data() {
    return {
      refreshTimer: null,
    };
  },

  mounted() {
    this.initiateUpdate();

    this.refreshTimer = setInterval(() => {
      this.fetchUpdatedImport();
    }, 2000);
  },

  methods: {
    initiateUpdate: function () {
      this.processUploadedProjectUsers({
        projectId: this.projectInfo.id,
        id: this.validationResponse.id,
      });
    },
    fetchUpdatedImport: function () {
      let payload = {
        route: `api/v1/projects/${this.projectInfo.id}/project_user_uploads/${this.validationResponse.id}`,
        relations: {
          validationAsset: "with",
        },
      };

      this.genericResource(payload).then((res) => {
        this.$emit("on-validation-response-change", res);
        if (res.rows_progressed == res.rows_progressed) {
          this.refreshTimer = null;
        }
      });
    },
    downloadErrorFile: function () {
      if (this.validationResponse.validation_asset.signedUrl != null) {
        window.open(this.validationResponse.validation_asset.signedUrl);
      }
    },
    ...mapActions({
      genericResource: "genericResource",
      processUploadedProjectUsers: "processUploadedProjectUsers",
    }),
  },

  computed: {
    ...mapGetters(["projectInfo"]),
    updateProgress: function () {
      if (this.validationResponse.total_records > 0) {
        let percentage =
          (this.validationResponse.rows_progressed /
            this.validationResponse.total_records) *
          100;
        return Math.round(percentage * 100) / 100;
      } else {
        return 100;
      }
    },
  },
};
</script>