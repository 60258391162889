<template>
  <b-dropdown-item
    @click="resendUserInvites"
  >
    {{ $t('TABLES.PROJECT_USER_NAVS.INVITE') }}
  </b-dropdown-item>
</template>

<script>
import { mapGetters } from "vuex";
import { RESEND_USER_INVITE_BULK } from "@/core/services/store/core/user.module";

export default {
  name: "ResendUserInvites",

  props: {
    checkedUsers: { type: Array, required: true }
  },

  methods: {
    resendUserInvites: function() {
      let payload = {
        projectId: this.projectInfo.id,
        users: this.checkedUsers
      };
      this.$store
        .dispatch(RESEND_USER_INVITE_BULK, payload)
        .then(() => {
          this.$emit('users_changed');
          this.alertSuccess();
        })
        .catch(err => {
          let errors = err.response.data.errors;
          this.alertFailure(errors);
        });
    },
    alertSuccess: function() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.RESEND_BULK_SUCCESS.TITLE")}`,
        text: `${this.$t("SWEETALERTS.RESEND_BULK_SUCCESS.MESSAGE")}`,
        icon: 'success',
        confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
      })
    },
    alertFailure: function(errors) {
      let type = "generic_failure";
      let translation = "RESEND_BULK_FAIL";

      if (errors.users != undefined) {
        if (errors.users.includes("Project does not contain the required settings to send to My Own Audience")) {
          type = "moa_failure";
          translation = "RESEND_BULK_FAIL_MOA";
        }
      }

      this.$swal.fire({
        title: `${this.$t(`SWEETALERTS.${translation}.TITLE`)}`,
        text: `${this.$t(`SWEETALERTS.${translation}.MESSAGE`)}`,
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: (type == "generic_failure") ? `${this.$t("GENERAL.TEXT.CONFIRM")}` : `${this.$t("GENERAL.TEXT.FIX")}`
      }).then((result) => {
        if (result.isConfirmed && type == "moa_failure") {
          this.$router.push({ name: "setup_theme", params: { tab: 3 } })
        }
      })
    }
  },

  computed: {
    ...mapGetters(["projectInfo"]),
  }
};
</script>
