<template>
  <a
    href="javascript:void(0)"
    @click="$emit('on-view-user-events')"
    class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
    v-b-tooltip.hover.bottom
    :title="$t('TABLES.PROJECT_USER_TOOLTIPS.EVENTS')"
  >
    <span class="svg-icon svg-icon-md svg-icon-primary">
      <inline-svg
        src="media/svg/icons/Code/Time-schedule.svg"
      />
    </span>
  </a>
</template>

<script>
export default {
  name: "ViewUserEvents",

  props: {
    user: { type: Object, required: true }
  }
}
</script>