<template>
  <div class="d-flex justify-content-between">
    <div class="col-xl-3 pl-0 pr-2">
      <ProjectUserFilter
        :error="error"
        :pageLoader="pageLoader"
        :systemProjectRoles="systemProjectRoles"
        :segmentationBreakdown="segmentationBreakdown"
        :projectInfo="projectInfo"
        :filter="filter"
        @on-filter-reset="filter = JSON.parse(JSON.stringify(filter_reset)), uncheckUsers(), fetchProjectUsers()"
        @on-filter-apply="uncheckUsers(), fetchProjectUsers()"
      >
      </ProjectUserFilter>
    </div>
    <div class="col-xl-9 pl-2 pr-0">
      <div class="col-xl-12 px-0">
        <div
          class="card card-custom card-stretch gutter-b"
          :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
        >
          <div class="card-header d-flex justify-content-between align-items-center">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('NAVIGATION.PROJECT_MENU.USERS') }}
                <small v-if="loadingComplete" class="ml-1">
                  {{ calculatedLoginsRemaining + ' users remaining' }}
                </small>
              </h3>
            </div>
            <ProjectUserToolbar
              v-if="loadingComplete && !error.active"
              :systemProjectRoles="systemProjectRoles"
              :checkedUsers="checked_users"
              :segmentations="projectSegmentations"
              :segments="projectSegments"
              :segmentationBreakdown="segmentationBreakdown"
              @users_changed="reFetchProjectUsers"
            >
            </ProjectUserToolbar>
          </div>
          <div class="card-body">

            <div class="d-flex justify-content-center mx-0" v-if="!loadingComplete && !error.active">
              <b-spinner
                variant="primary"
                label="Spinning"
                class="table-loader my-3"
              >
              </b-spinner>
            </div>

            <RegistrationAlerts
              :loadingComplete="loadingComplete"
              :error="error"
              :events="outstandingRegistrationAlerts"
              :filter="filter"
              @on-filter-register-alert-change="filter.x_filter_registrationAlert = $event"
              @on-view-all-alerts="filteredRegisterEvents.user = null,
                filteredRegisterEvents.events = outstandingRegistrationAlerts,
                $refs.eventsModal.$refs.modal.show()"
            />

            <ProjectUserTable
              v-if="loadingComplete && !error.active"
              ref="projectUserTable"
              :error="error"
              :pageLoader="pageLoader"
              :projectUsers="projectUsers"
              :checkedUsers="checked_users"
              :events="eventList"
              @user_checked="checked_users.push($event)"
              @user_unchecked="uncheckUser($event)"
              @all_unchecked="pageLoader.refreshed = false"
              @users_changed="reFetchProjectUsers"
              @on-view-user-events="filteredRegisterEvents.user = $event.user,
                filteredRegisterEvents.events = $event.events,
                $refs.eventsModal.$refs.modal.show()"
            >
            </ProjectUserTable>

            <Pagination
              v-if="loadingComplete && !error.active"
              :meta="projectUsersMeta"
              :pageLoader="pageLoader"
              @change_page="applyNewPage"
              @change_size="applyPageLength"
            >
            </Pagination>

            <b-alert v-if="error.active" show variant="light">
              {{ error.message }}
            </b-alert>

          </div>
        </div>
      </div>
    </div>

    <EventsModal
      ref="eventsModal"
      :title="$t('NAVIGATION.PROJECT_MENU.REGISTRATION_ERRORS_PLURAL')"
      :subtitle="(filteredRegisterEvents.user != null) ? filteredRegisterEvents.user.display_name : null"
      :events="filteredRegisterEvents.events"
      :showFilter="false"
    />

  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/system/breadcrumbs.module";
import { POPULATE_PROJECT_ROLES } from "@/core/services/store/system/system.module";
import { POPULATE_PROJECT_SEGMENTATION_LIST } from "@/core/services/store/project/project_segmentations.module"
import { POPULATE_PROJECT_SEGMENT_LIST } from "@/core/services/store/project/project_segments.module";

import ProjectUserFilter from "@/view/pages/user_management/project_user/Filter";
import ProjectUserTable from "@/view/pages/user_management/project_user/Table";
import ProjectUserToolbar from "@/view/pages/user_management/project_user/Toolbar";
import RegistrationAlerts from "@/view/pages/user_management/project_user/Alerts";
import Pagination from "@/view/components/Pagination";
import EventsModal from "@/view/content/modals/EventsModal";

export default {
  mixins: [validationMixin],

  name: "ProjectUsers",

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 4,
        filtering: false,
        refreshed: false
      },
      error: {
        active: false,
        message: ""
      },
      segmentationBreakdown: [],
      checked_users: [],
      filter: {
        x_filter_name: "",
        x_filter_roles: [],
        x_filter_status: [],
        x_filter_sources: [],
        x_filter_segmentFilterOption: 1,
        x_filter_segments: [],
        x_filter_loginFilterOption: 1,
        x_filter_loginDates: {
          startDate: null,
          endDate: null
        },
        x_filter_registrationAlert: 0
      },
      filter_reset: null,
      page_length: null,
      filteredRegisterEvents: {
        user: null,
        events: null
      }
    }
  },

  validations: {
    filter: {
      x_filter_name: {},
      x_filter_roles: {},
      x_filter_status: {},
      x_filter_sources: {},
      x_filter_segments: {},
      x_filter_loginDates: {}
    }
  },

  components: {
    ProjectUserFilter,
    ProjectUserTable,
    ProjectUserToolbar,
    RegistrationAlerts,
    Pagination,
    EventsModal
  },

  mounted() {
    this.cleanup();
    this.filter_reset = JSON.parse(JSON.stringify(this.filter));

    if (this.currentUser.relation_to_project != "super_admin" && this.currentUser.relation_to_project != "admin") {
      this.error.active = true;
      this.error.message = "You do not have the correct permissions to view this resource";
    } 

    let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.$t("NAVIGATION.ROOT_MENU.CLIENTS")}`, route: protocol + 'admin.' + process.env.VUE_APP_API_ROOT + "/#/clients/view" },
      { title: this.clientInfo.name, route: protocol + this.clientInfo.key + ".admin." + process.env.VUE_APP_API_ROOT + "/#/client/projects" },
      {
        title: this.projectInfo.name,
        route: "/project/view"
      },
      { title: `${this.$t("NAVIGATION.PROJECT_MENU.USERS")}` }
    ]);

    this.fillRouteParams();
    this.fetchProjectRoles();
    this.fetchProjectSegmentations();
    this.fetchProjectUsers();
    this.fetchRegistrationErrors();
  },

  methods: {
    fillRouteParams: function() {
      if (this.$route.params.segment != undefined) {
        if (this.$route.params.segment != 'no_segment') {
          this.filter.x_filter_segments = [this.$route.params.segment];
          this.filter.x_filter_segmentFilterOption = 1;
        } else {
          this.filter.x_filter_segmentFilterOption = 4;
        }
      }
      if (this.$route.params.status != undefined) {
        if (this.$route.params.status == 'subscribed') {
          this.filter.x_filter_status = ['1'];
        } else if (this.$route.params.status == "invited") {
          this.filter.x_filter_status = ['2'];
        }
      }
    },
    fetchProjectRoles: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {}
      };
      this.$store
        .dispatch(POPULATE_PROJECT_ROLES, payload)
        .then(() => {
          this.formatProjectRoles();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project roles list, please check back later or contact the helpdesk";
        });
    },
    formatProjectRoles: function() {
      this.systemProjectRoles.forEach(role => {
        role.label = `${this.$t("SYSTEM.PROJECT_ROLES." + role.id)}`
      })
      this.pageLoader.componentsCompleted++;
    },
    fetchProjectSegmentations() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENTATION_LIST, payload)
        .then(() => {
          this.fetchProjectSegments();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segmentations list, please check back later or contact the helpdesk";
        });
    },
    fetchProjectSegments() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENT_LIST, payload)
        .then(() => {
          this.mergeSegmentationsAndSegments();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segments list, please check back later or contact the helpdesk";
        });
    },
    mergeSegmentationsAndSegments() {
      let segmentationBreakdown = [];
      var i;
      for (i = 0; i < this.projectSegmentations.length; i++) {
        let segmentationId = this.projectSegmentations[i].id
        let parentObject = { id: this.projectSegmentations[i].name, label: this.projectSegmentations[i].name };
        let children = [];
        var j;
        for (j = 0; j < this.projectSegments.length; j++) {
          if (this.projectSegments[j].project_segmentation_id == segmentationId) {
            let segmentId = this.projectSegments[j].id;
            let childObject = { id: segmentId, label: this.projectSegments[j].name }
            children.push(childObject);
          }
        }
        parentObject.children = children;
        (children.length == 0 ? parentObject.isDisabled = true : parentObject.isDisabled = false);
        segmentationBreakdown.push(parentObject);
      }
      this.segmentationBreakdown = segmentationBreakdown;
      this.pageLoader.componentsCompleted++;
    },
    fetchProjectUsers: function(page = 1) {
      this.pageLoader.filtering = true;

      let payload = {
        route: `api/v1/projects/${this.projectInfo.id}/users`,
        projectId: this.projectInfo.id,
        data: {
          projectId: this.projectInfo.id
        },
        attributes: [
          'projectRelation'
        ],
        params: {
          paginate: 1,
          page: page,
          pageLength: this.page_length
        },
        relations: {
          activeProjectRole: 'with',
          invitedProjectRole: 'with',
          activeSegments: 'with',
          invitedSegments: 'with',
          projectAsset: 'with',
          projectProfiles: 'with',
          assignedProjectInvites: 'with',
        }
      };

      payload.params = {
        ...this.filter,
        ...payload.params,
      }

      this.$store.dispatch(GENERIC_RESOURCE, payload)
        .then((res) => {
          let projectUsers = res.data._embedded.users;
          this.setProjectUsersList(projectUsers);
          if (res.meta != undefined) {
            this.setProjectUsersMeta(res.meta);
          }

          if (this.pageLoader.componentsCompleted < this.pageLoader.componentsRequired) {
            this.pageLoader.componentsCompleted++;
          } else {
            this.checked_users = [];
            this.pageLoader.refreshed = true;            
          }
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segments list, please check back later or contact the helpdesk";
        })
        .finally(() => {
          this.pageLoader.filtering = false;
          this.pageLoader.refreshed = true;
        });
    },
    fetchRegistrationErrors: function() {
      let payload = {
        route: `api/v1/projects/${this.projectInfo.id}/events`,
        params: {
          paginate: false
        },
        filter: {
          target_type: "User"
        },
        order: {
          created_at: 'ASC'
        }
      };

      this.genericResource(payload).then((res) => {
        this.setEventList(res._embedded.events);
        this.pageLoader.componentsCompleted++;
      }).catch((error) => {
        this.setEventErrors(error.message);
      })
    },
    uncheckUsers: function() {
      this.$refs.projectUserTable.checkUsers(this.checked_users,false);
    },
    reFetchProjectUsers: function() {
      this.uncheckUsers();
      this.fetchProjectUsers();
    },
    applyPageLength: function(value) {
      this.page_length = value;
      this.fetchProjectUsers(1);
    },
    applyNewPage: function(value) {
      this.fetchProjectUsers(value)
    },
    uncheckUser: function(value) {
      const index = this.checked_users.indexOf(value);
      this.checked_users.splice(index, 1);
    },
    cleanup: function() {
      this.setProjectUsersList();
      this.setProjectUsersMeta();
      this.setEventList();
      this.setEventErrors();
    },
    ...mapActions({
      genericResource: "genericResource"
    }),
    ...mapMutations({
      setProjectUsersList: "setProjectUsersList",
      setProjectUsersMeta: "setProjectUsersMeta",
      setEventList: "setEventList",
      setEventErrors: "setEventErrors"
    })
  },

  watch: {
    'filter.x_filter_registrationAlert': {
      handler(newValue) {
        if (newValue) {
          this.filter = JSON.parse(JSON.stringify(this.filter_reset));
          this.filter.x_filter_registrationAlert = 1;
          this.page_length = "All";
          this.fetchProjectUsers();
        } else {
          this.filter = JSON.parse(JSON.stringify(this.filter_reset));
          this.page_length = '15';
          this.fetchProjectUsers();
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      "clientInfo",
      "projectInfo",
      "systemProjectRoles",
      "projectSegmentations",
      "projectSegments",
      "projectUsers",
      "projectUsersMeta",
      "currentUser",
      "eventList"
    ]),
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    },
    calculatedLoginsRemaining: function() {
      let participantLimit = (this.projectInfo.participant_limit == 1) ? 100 : 500;

      return participantLimit - this.projectInfo.totalParticipants.total;
    },
    outstandingRegistrationAlerts: function() {
      if (this.eventList == null) {
        return []
      } else {
        return this.eventList.filter(function(event) {
          return event.is_active == 1 && event.system_event_type_id >= 3
        });        
      }
    }
  }
};
</script>
