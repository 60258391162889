<template>
  <div
    id="input-filter-segment-choices"
    role="group"
    class="form-row form-group w-100 mb-0 text-right"
    :class="{ 'd-flex justify-content-center' : minimal == true }"
  >
    <label
      v-if="!computedMinimal"
      class="col-sm-4 col-lg-3 col-form-label"
    >
      {{ $t('GENERAL.TEXT.IN') }}
    </label>
    <div
      class="row pl-5 filter-options-row"
      :class="computedFlexClass"
    >
      <b-button
        class="btn-sm mt-2 mr-1"
        :variant="opt1Variant"
        v-b-tooltip.hover.bottom
        :title="$t('GENERAL.TOOLTIP.ALL')"
        @click="emitChange(1)"
      >
        {{ $t('GENERAL.TEXT.ALL') }}
      </b-button>
      <b-button
        class="btn-sm mt-2 mr-1"
        :variant="opt2Variant"
        v-b-tooltip.hover.bottom
        :title="$t('GENERAL.TOOLTIP.ANY')"
        @click="emitChange(2)"
      >
        {{ $t('GENERAL.TEXT.ANY') }}
      </b-button>
      <b-button
        class="btn-sm mt-2"
        :variant="opt3Variant"
        v-b-tooltip.hover.bottom
        :title="$t('GENERAL.TOOLTIP.NONE_OF')"
        @click="emitChange(3)"
      >
        {{ $t('GENERAL.TEXT.NONE_OF') }}
      </b-button>
      <b-button
        class="btn-sm mt-2"
        :variant="opt4Variant"
        v-b-tooltip.hover.bottom
        :title="$t('GENERAL.TOOLTIP.NONE_AT_ALL')"
        @click="emitChange(4)"
      >
        {{ $t('GENERAL.TEXT.NONE_AT_ALL') }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterOptionsSegments",

  props: {
    segmentFilterOption: { type: Number, required: true },
    minimal: { type: Boolean, required: false }
  },

  methods: {
    emitChange: function(val) {
      this.$emit('filterOptionChanged',val);
    }
  },

  computed: {
    opt1Variant: function() {
      return (this.segmentFilterOption == 1) ? 'primary' : 'primary-outline';
    },
    opt2Variant: function() {
      return (this.segmentFilterOption == 2) ? 'primary' : 'primary-outline';
    },
    opt3Variant: function() {
      return (this.segmentFilterOption == 3) ? 'primary' : 'primary-outline';
    },
    opt4Variant: function() {
      return (this.segmentFilterOption == 4) ? 'primary' : 'primary-outline';
    },
    computedMinimal: function() {
      if (this.minimal != undefined) {
        return this.minimal;
      } else {
        return false;
      }
    },
    computedFlexClass: function() {
      if (this.computedMinimal) {
        return "d-flex justify-content-center col-xl-12";
      } else {
        return "d-flex justify-content-start col-sm-8 col-lg-9";
      }
    }
  }
}
</script>
