<template>
  <div>
    <b-dropdown-item v-b-modal.report-modal>
      {{ $t('TABLES.PROJECT_USER_NAVS.GENERATE_REPORTS') }}
    </b-dropdown-item>

    <b-modal size="md" ref="report-modal" id="report-modal" :title="$t('TABLES.PROJECT_USER_NAVS.GENERATE_REPORT')">
      <div class="row col-xl-12 d-flex justify-content-start mb-10 mt-3 px-0 mx-0">
        <ErrorBox :errors="projectReportsErrors" @clear_errors="clearErrors">
        </ErrorBox>
        <InputGeneric
          id="input-title"
          class="col-xl-12 px-0"
          :model="$v.form.title"
          :placeholder="$t('FORMS.INPUTS.TITLE')"
          :showInline="false"
          :submissionStates="submissionStates"
          :error="$t('FORMS.ERROR.REQUIRED')"
        >
        </InputGeneric>
      </div>
      <div class="row col-xl-12 d-flex justify-content-start mb-0 px-0 mx-0">
        <InputGenericTextArea
          class="col-xl-12 px-0"
          id="input-notes"
          :model="$v.form.notes"
          :submissionStates="submissionStates"
          rows="4"
          :label="$t('FORMS.INPUTS.NOTES')"
          displayLabel="0"
        >
        </InputGenericTextArea>
      </div>
      <div class="row col-xl-12 d-flex justify-conent-start mb-0 px-0 mx-0">
        <InputGenericCheckbox
          class="col-xl-12 px-0 mb-0"
          id="input-separate-mm"
          :label="$t('FORMS.INPUTS.SEPARATE_MM')"
          :model="$v.form.separate_mm"
          :submissionStates="submissionStates"
        >
        </InputGenericCheckbox>
      </div>
      <template #modal-footer class="py-0">
        <div class="d-flex justify-content-between my-0 py-0">
          <a
            href="javascript:void(0)"
            class="btn btn-sm btn-secondary mr-2"
            @click="closeModal"
          >
            {{ $t('GENERAL.BUTTONS.CANCEL') }}
          </a>
          <a
            href="javascript:void(0)"
            class="btn btn-sm btn-primary mr-2"
            @click="onSubmit"
          >
            {{ $t('GENERAL.BUTTONS.GENERATE') }}
          </a>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { maxLength, required } from "vuelidate/lib/validators";

import {
  CREATE_PROJECT_REPORT,
  CLEAR_PROJECT_REPORTS_ERRORS
} from "@/core/services/store/project/project_reports.module";

import ErrorBox from "@/view/components/ErrorBox";
import InputGeneric from "@/view/components/form-inputs/GenericInput";
import InputGenericTextArea from "@/modules/together-helpers/components/generic-inputs/GenericTextArea";
import InputGenericCheckbox from "@/modules/together-helpers/components/generic-inputs/GenericCheckbox";

export default {
  mixins: [validationMixin],

  name: "GenerateReport",

  props: {
    checkedUsers: { type: Array, required: true }
  },

  data() {
    return {
      submissionStates: {
        submitting: false
      },
      form: {
        title: "",
        notes: "",
        separate_mm: 0
      }
    }
  },

  validations: {
    form: {
      title: {
        required,
        maxLength: maxLength(255)
      },
      notes: {},
      separate_mm: {
        required
      }
    }
  },

  components: {
    ErrorBox,
    InputGeneric,
    InputGenericTextArea,
    InputGenericCheckbox
  },

  methods: {
    onSubmit: function() {
      this.clearErrors();

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.submissionStates.submitting = true;

      let formData = this.form;
      formData.users = this.checkedUsers;
      formData.type = 1;

      let payload = {
        projectId: this.projectInfo.id,
        data: formData
      };
      this.$store
        .dispatch(CREATE_PROJECT_REPORT, payload)
        .then(() => {
          this.closeModal();
          this.alertSuccess();
        })
        .catch(() => {
          this.alertFailure();
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        })
    },
    closeModal: function() {
      this.$refs['report-modal'].hide();
    },
    alertSuccess: function() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.GENERATE_REPORT_SUCCESS.TITLE")}`,
        text: `${this.$t("SWEETALERTS.GENERATE_REPORT_SUCCESS.MESSAGE")}`,
        icon: 'success',
        confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
      })
    },
    alertFailure: function() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.GENERATE_REPORT_FAIL.TITLE")}`,
        text: `${this.$t("SWEETALERTS.GENERATE_REPORT_FAIL.MESSAGE")}`,
        icon: 'error',
        confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
      })
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_PROJECT_REPORTS_ERRORS);
    }
  },

  computed: {
    ...mapGetters(["projectInfo","projectReportsErrors"]),
  }
};
</script>
