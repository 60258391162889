<template>
  <div
    v-if="loadingComplete && !error.active && events.length > 0"
    class="d-flex justify-content-start align-items-center mb-7 pl-5"
  >
    <i
      class="fas fa-exclamation-triangle text-danger"
      style="font-size:1.1rem"
    />

    <span style="padding-top: 1px">
      <a
        href="javascript:void(0)"
        class="text-danger"
        @click="$emit('on-view-all-alerts')"
      >
        <span class="font-weight-bold pl-1">
          {{ `${events.length}` }}
        </span>
        <span>
          {{
            (events.length == 1) ?
              $t('NAVIGATION.PROJECT_MENU.REGISTRATION_ERRORS_SINGULAR') :
              $t('NAVIGATION.PROJECT_MENU.REGISTRATION_ERRORS_PLURAL')
          }}
        </span>
      </a>
      <a
        href="javascript:void(0)"
        class="pl-1 inline-toggle-filter"
        @click="$emit('on-filter-register-alert-change', !filter.x_filter_registrationAlert)"
      >
        {{
          (filter.x_filter_registrationAlert) ?
            $t('NAVIGATION.PROJECT_MENU.CANCEL_FILTER') :
            $t('NAVIGATION.PROJECT_MENU.APPLY_FILTER')
        }}
      </a>
    </span>
  </div>
</template>

<script>
export default {
  name: "UserRegistrationAlerts",

  props: {
    loadingComplete:  { type: Boolean, required: true },
    error:            { type: Object, required: true },
    events:           { type: Array, required: false, default: function() { return [] } },
    filter:           { type: Object, required: true }
  }
}
</script>