<template>
  <div>
    <div
      class="dialog-container mt-10"
      :class="{
        'd-flex align-items-center': validationResponse == null,
        'px-10 py-5': validationResponse != null,
      }"
      v-if="submitting == true || validationResponse != null"
    >
      <b-spinner
        variant="primary"
        label="Spinning"
        class="card-loader"
        v-if="validationResponse == null"
      />

      <div v-else>
        <div class="d-flex justify-content-between align-items-center">
          <h6>{{ $t("TABLES.PROJECT_USER_UPLOAD.VALIDATION_SUMMARY") }}</h6>
          <a
            v-if="
              !criticalErrorPresent &&
              nonCriticalErrorCount > 0
            "
            href="javascript:void(0)"
            class="pb-2"
            @click="downloadValidationFile"
          >
            {{ $t("TABLES.PROJECT_USER_UPLOAD.VALIDATE_ERROR_FILE_DOWNLOAD") }}
          </a>
        </div>

        <div class="py-5 mt-5">
          <div class="row">
            <span class="col-6 text-right mb-1"
              >{{
                $t("TABLES.PROJECT_USER_UPLOAD.VALIDATE_TOTAL_RECORDS")
              }}:</span
            >
            <span class="col-6 text-left">{{
              validationResponse.total_records == null
                ? 0
                : validationResponse.total_records
            }}</span>
          </div>

          <div class="row">
            <span class="col-6 text-right mb-1"
              >{{
                $t("TABLES.PROJECT_USER_UPLOAD.VALIDATE_SUCCESSFUL_USERS")
              }}:</span
            >
            <span class="col-6 text-left">{{
              validationResponse.new_user_count == null
                ? 0
                : validationResponse.new_user_count
            }}</span>
          </div>

          <div class="row">
            <span class="col-6 text-right mb-1"
              >{{
                $t(
                  "TABLES.PROJECT_USER_UPLOAD.VALIDATE_SUCCESSFUL_SEGMENTATIONS"
                )
              }}:</span
            >
            <span class="col-6 text-left">{{
              validationResponse.new_segmentation_count == null
                ? 0
                : validationResponse.new_segmentation_count
            }}</span>
          </div>

          <div class="row">
            <span class="col-6 text-right mb-1"
              >{{
                $t("TABLES.PROJECT_USER_UPLOAD.VALIDATE_FORMATTING_ERRORS")
              }}:</span
            >
            <span class="col-6 text-left">
              <div class="d-inline-flex align-items-center mr-4">
                <i class="fas fa-exclamation-circle text-danger mr-2" />
                {{ validationResponse.critical_error_count }}
                {{ $t("TABLES.PROJECT_USER_UPLOAD.VALIDATE_CRITICAL") }}
              </div>
              <div class="d-inline-flex align-items-center">
                <i class="fas fa-exclamation-triangle text-warning mr-2" />
                {{ nonCriticalErrorCount }}
                {{ $t("TABLES.PROJECT_USER_UPLOAD.VALIDATE_NON_CRITICAL") }}
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-between mt-5 px-2"
      v-if="validationResponse != null"
    >
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="$emit('on-reset')"
      >
        Cancel
      </button>

      <button
        type="button"
        class="btn btn-sm btn-primary"
        :disabled="criticalErrorPresent || validationResponse.total_records == 0"
        @click="onImportSubmit"
      >
        {{ $t("TABLES.PROJECT_USER_UPLOAD.VALIDATE_IMPORT") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileImportValidate",

  props: {
    submitting: { type: Boolean, required: true },
    validationResponse: { type: Object, required: false },
    criticalErrorPresent: { type: Boolean, required: true },
    nonCriticalErrorCount: { type: Number, required: true },
  },

  methods: {
    onImportSubmit: function () {
      this.$emit("on-import-change", true);
    },
    downloadValidationFile: function () {
      if (this.validationResponse.validation_asset.signedUrl != null) {
        window.open(this.validationResponse.validation_asset.signedUrl);
      }
    },
  },
};
</script>