<template>
  <b-modal
    ref="modal"
    size="xl"
    :hide-footer="true"
  >
    <template #modal-title>
      {{ title }}
      <small
        v-if="subtitle != null"
        class="text-muted"
      >
        {{ subtitle }}
      </small>
    </template>

    <EventsTable
      :showFilter="false"
      :events="events"
    />
  </b-modal>
</template>

<script>
import EventsTable from "@/view/components/tables/EventsTable";

export default {  
  name: "EventsModal",

  props: {
    title:      { type: String, required: true },
    subtitle:   { type: String, required: false, default: null },
    events:     { type: Array, required: false, default: null },
    showFilter: { type: Boolean, required: false, default: false }
  },

  components: {
    EventsTable
  }
}
</script>