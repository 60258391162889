<template>
  <tr
    v-on:mouseover="hovering = 1"
    v-on:mouseleave="hovering = 0" 
  >
    <td class="px-2 py-6 text-center">
      <b-form-checkbox v-model="rowChecked" size="lg"></b-form-checkbox>
    </td>
    <td class="pt-4">
      <UserThumbnail
        :canOpenProfile="currentUser.menu_items.includes('AdminProjectUsers')"
        :user="projectUser"
        @open_profile_modal="$emit('open_profile_modal', $event)"
      >
      </UserThumbnail>
    </td>
    <td class="px-2 py-3 pt-4 d-table-cell">
      <router-link
        tag="a"
        :to="{ name: 'edit_project_user', params: {id: projectUser.id } }"
        class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
      >
        {{ projectUser.tableDisplayEmail }}
      </router-link>
      <div 
        class="d-flex justify-content-start align-items-center"
      >
        <span
          v-if="calculatedSubscribed && projectUser.name != 'invited_user'"
          class="text-muted font-weight-bold text-muted d-block font-size-sm"
        >
          <AnonymisedUsername :user="projectUser"></AnonymisedUsername>
        </span>

        <a
          v-if="calculatedRegistrationErrors.length > 0"
          href="javascript:void(0)"
          class="pl-2"
          @click="$emit('on-view-user-events', { user: projectUser, events: calculatedRegistrationErrors })"
        >
          <i
            class="fas fa-exclamation-triangle text-danger"
            style="font-size:1.1rem"
          />

          <span class="text-danger">
            {{
              (calculatedRegistrationErrors.length == 1) ?
                calculatedRegistrationErrors[0].summary :
                `${calculatedRegistrationErrors.length} ` + $t(`NAVIGATION.PROJECT_MENU.REGISTRATION_ERRORS_PLURAL`)
            }}
          </span>
        </a>
      </div>

    </td>
    <td class="px-2 py-4 pt-6 text-left d-table-cell font-size-sm">
      <span
        class="label label-dot mr-2"
        :class="calculatedUserStatus"
        v-b-tooltip.hover.left
        :title="calculatedUserTooltip"
      >
      </span>
      {{ calculatedProjectRole }}
    </td>
    <td class="px-2 py-4 pt-6 text-left d-table-cell font-size-sm text-center">
      <span
        v-if="relevantUserSample != undefined"
        class="label label-pill label-inline ml-3"
        :class="(relevantUserSample.id == 1) ? 'label-outline-primary' : 'label-light-primary'"
        style="position:relative; top:-1px"
      >
        {{ relevantUserSample.name }}
      </span>
    </td>
    <td class="px-4 py-4 pt-6 text-left d-table-cell font-size-sm">
      <span v-if="projectUser.last_login == null"> {{ $t("TABLES.PROJECT_USER_TOOLTIPS.NEVER") }} </span>
      <GenericDatetimeDisplay
        v-else
        :date="projectUser.last_login"
        :lastUpdated="true"
        :relative="true"
      >
      </GenericDatetimeDisplay>
    </td>
    <td class="px-2 py-4 pt-4 text-right d-table-cell text-right">
      <div v-show="hovering" class="px-3">
        <ViewUserEvents
          v-show="calculatedSubscribed"
          :user="projectUser"
          @on-view-user-events="$emit('on-view-user-events', { user: projectUser, events: userEvents })"
        />
        <ViewUserPosts v-show="calculatedSubscribed" :user="projectUser" />
        <SendPasswordReset v-show="calculatedSubscribed" :user="projectUser" />
        <ResendInvite
          v-show="!calculatedSubscribed"
          :user = projectUser
          :projectId="projectInfo.id"
          @users_changed="$emit('users_changed')"
        />
        <DeleteProjectUser :user="projectUser" />
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";

import ViewUserEvents from "@/view/components/table-actions/ViewUserEvents.vue";
import ViewUserPosts from "@/view/components/table-actions/ViewUserPosts.vue";
import SendPasswordReset from "@/view/components/sweet-alerts/SendPasswordReset.vue";
import ResendInvite from "@/view/components/sweet-alerts/ResendInvite.vue";
import DeleteProjectUser from "@/view/components/table-actions/DeleteProjectUser.vue";
import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

export default {
  name: "ProjectUserRow",

  props: {
    projectUser:        { type: Object, required: true },
    checkAll:           { type: Boolean, required: true },
    events:             { type: Array, required: true }
  },

  data() {
    return {
      userId: null,
      rowChecked: false,
      hovering: false
    }
  },

  components: {
    ViewUserEvents,
    ViewUserPosts,
    SendPasswordReset,
    ResendInvite,
    DeleteProjectUser,
    UserThumbnail,
    GenericDatetimeDisplay
  },

  methods: {
    check: function(checkedState) {
      this.rowChecked = checkedState;
    }
  },

  watch: {
    checkAll: function(newValue) {
      (newValue == true) ? this.rowChecked = true : this.rowChecked = false;
    },
    rowChecked: function(newValue) {
      (newValue == true) ? this.$emit('user_checked',this.projectUser.id) : this.$emit('user_unchecked',this.projectUser.id);
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "currentUser",
      "systemProjectUserSamples"
    ]),
    userEvents: function() {
      const self = this;
      return this.events.filter(function(event) {
        return event.target_id === self.projectUser.id &&
          event.target_type == "User"
      });
    },
    calculatedRegistrationErrors: function() {
      return this.userEvents.filter(function(event) {
        return event.is_active == 1 &&
          event.system_event_type_id >= 3
      });
    },
    calculatedUserStatus: function() {
      switch (this.projectUser.projectRelation.setup) {
        case 1:
          return "label-dark";
        case 2:
          return "label-secondary"
        case 3:
        default:
          return "label-warning";
        case 4:
          return "label-success";
        case 5:
          return "label-danger";
      }
    },
    calculatedUserTooltip: function() {
      switch (this.projectUser.projectRelation.setup) {
        case 1:
          return `${this.$t('GENERAL.STATUS.SYSTEM_BLOCKED')}`;
        case 2:
          return `${this.$t('TABLES.PROJECT_USER_TOOLTIPS.NO_INVITE')}`;
        case 3:
        default:
          return `${this.$t('TABLES.PROJECT_USER_TOOLTIPS.INCOMPLETE')}`;
        case 4:
          return `${this.$t('TABLES.PROJECT_USER_TOOLTIPS.COMPLETE')}`;
        case 5:
          return `${this.$t('TABLES.PROJECT_USER_TOOLTIPS.PROJECT_BLOCKED')}`;
      }
    },
    calculatedProjectRole: function() {
      let projectRole = null;
      let invitedRole = null;

      if (this.projectUser.active_project_role.length > 0) {
        this.projectUser.active_project_role.forEach(role => {
          if (role.project_profile.project_id == this.projectInfo.id) {
            projectRole = role;
          };
        });
      };

      if (projectRole != null) {
        return projectRole.name;
      } else {
        this.projectUser.invited_project_role.forEach(role => {
          if (role.assigned_project_invite.project_id == this.projectInfo.id) {
            invitedRole = role;
          };
        });

        return invitedRole.name;
      }
    },
    calculatedSubscribed: function() {
      let projectRole = null;

      if (this.projectUser.active_project_role.length > 0) {
        this.projectUser.active_project_role.forEach(role => {
          if (role.project_profile.project_id == this.projectInfo.id) {
            projectRole = role;
          };
        });
      };

      if (projectRole != null) {
        return true;
      } else {
        return false;
      }
    },
    relevantProfile: function() {
      let projectId = this.projectInfo.id;

      let invites = this.projectUser.assigned_project_invites.filter(function(invite) {
        return invite.project_id == projectId;
      });

      let profiles = this.projectUser.project_profiles.filter(function(profile) {
        return profile.project_id == projectId;
      });

      if (invites.length > 0) {
        return invites[0];
      } else if (profiles.length > 0) {
        return profiles[0];
      } else {
        return null;
      }
    },
    relevantUserSample: function() {
      let systemProjectUserSampleId = this.relevantProfile.system_project_user_sample_id;

      return this.systemProjectUserSamples.filter(function(sample) {
        return sample.id == systemProjectUserSampleId;
      })[0];
    }
  }
}
</script>
