<template>
  <div class="card-toolbar">
    <b-dropdown
      id="user-actions-dropdown"
      class="mr-2"
      :text="$t('TABLES.PROJECT_USER_NAVS.SELECTED') + ' (' + checkedUsersCount.toString() + ')'"
      size="sm"
      variant="primary"
      :disabled="checkedUsersCount == 0"
    >
      <!--start::Resend Invites-->
      <ResendInvites
        :checkedUsers="checkedUsers"
        @users_changed="emitUserChange"
      >
      </ResendInvites>
      <!--end::Resend Invited-->

      <!--start::Block Users-->
      <BlockUsers
        :checkedUsers="checkedUsers"
        @users_changed="emitUserChange"
      >
      </BlockUsers>
      <!--end::Block Users-->

      <!--start::Unblock Users-->
      <UnblockUsers
        :checkedUsers="checkedUsers"
        @users_changed="emitUserChange"
      >
      </UnblockUsers>
      <!--end::Unblock Users-->
      
      <!--start::Add To Segment-->
      <AddSegment
        :checkedUsers="checkedUsers"
        :segmentChoices="options"
        @users_changed="emitUserChange"
      >
      </AddSegment>
      <!--end::Add To Segment-->

      <!--start::Remove From Segment-->
      <RemoveSegment
        :checkedUsers="checkedUsers"
        :segmentChoices="options"
        @users_changed="emitUserChange"
      >
      </RemoveSegment>
      <!--end::Remove From Segment-->

      <!--start::Update Source-->
      <UpdateSource
        :checkedUsers="checkedUsers"
        @users_changed="emitUserChange"
      >
      </UpdateSource>
      <!--end::Update Source-->

      <!--start::Delete Users-->
      <DeleteUsers
        :checkedUsers="checkedUsers"
        @users_changed="emitUserChange"
      >
      </DeleteUsers>
      <!--end::Delete Users-->

      <!-- here be dragons - temporarily disabling this while this feautre is under construction - J -->
      <GenerateReport
        v-if="false"
        :checkedUsers="checkedUsers"
      >
      </GenerateReport>
    </b-dropdown>
    <router-link
      tag="a"
      class="btn btn-sm btn-primary font-weight-bold mr-2"
      :to="{ name: 'add_project_user' }"
    >
      {{ $t('TABLES.PROJECT_USER_NAVS.ADD') }}
    </router-link>
    <UserImport
      :segmentationBreakdown="segmentationBreakdown"
      @users_changed="emitUserChange"
    >
    </UserImport>
  </div>
</template>

<script>
import ResendInvites from "./toolbar_options/ResendInvites";
import BlockUsers from "./toolbar_options/BlockUsers";
import UnblockUsers from "./toolbar_options/UnblockUsers";
import AddSegment from "./toolbar_options/AddToSegment";
import RemoveSegment from "./toolbar_options/RemoveFromSegment";
import UpdateSource from "./toolbar_options/UpdateSource";
import DeleteUsers from "./toolbar_options/DeleteUsers";
import GenerateReport from "./toolbar_options/GenerateReport";
import UserImport from "./toolbar_options/UserImport";

export default {
  name: "ProjectUserToolbar",

  props: {
    systemProjectRoles: { type: Array, required: true },
    checkedUsers: { type: Array, required: true },
    segmentations: { type: Array, required: true },
    segments: { type: Array, required: true },
    segmentationBreakdown: { type: Array, required: true }
  },

  data() {
    return {
      options: {}
    }
  },

  components: {
    ResendInvites,
    BlockUsers,
    UnblockUsers,
    AddSegment,
    RemoveSegment,
    UpdateSource,
    DeleteUsers,
    GenerateReport,
    UserImport
  },

  mounted() {
    const self = this;
    this.segmentations.forEach(function (segmentation) {
      self.options[segmentation.name] = {};
      let segments = self.segments.filter(function( segment ) {
        return segment.project_segmentation_id == segmentation.id;
      });
      segments.forEach(function (segment) {
        self.options[segmentation.name][segment.id] = segment.name;
      })
    });
  },

  methods: {
    emitUserChange: function() {
      this.$emit('users_changed');
    }
  },

  computed: {
    checkedUsersCount: function() {
      return this.checkedUsers.length;
    }
  }
}
</script>
