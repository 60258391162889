<template>
  <div
    class="d-flex align-items-center flex-wrap"
    :class="{ 'justify-content-end' : selected_page_length == 'All', 'justify-content-between' : selected_page_length != 'All' }"
  >
    <div v-if="selected_page_length != 'All'" class="d-flex flex-wrap py-2 mr-3">
        <!-- begin:: First Page -->
        <a
          href="javascript:void(0)"
          class="btn btn-icon btn-sm btn-light mr-2 my-1"
          @click="goToPage(1)"
          :disabled="meta.current_page == 1"
        >
          <i class="ki ki-bold-double-arrow-back icon-xs"></i>
        </a>
        <!-- end:: First Page -->
        <!-- begin:: Down Page -->
        <a
          href="javascript:void(0)"
          class="btn btn-icon btn-sm btn-light mr-2 my-1"
          @click="goToPage(meta.current_page - 1)"
          :disabled="meta.current_page == 1"
        >
          <i class="ki ki-bold-arrow-back icon-xs"></i>
        </a>
        <!-- end:: Down Page -->
        <!-- begin:: Selectable Pages -->
        <template v-for="(page, i) in computedPages">
          <a
            :key="i"
            href="javascript:void(0)"
            class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1"
            :class="{ 'btn-hover-primary active' : page == meta.current_page }"
            @click="goToPage(page)"
          >
            {{ page }}
          </a>
        </template>
        <!-- end:: Selectable Pages -->
        <!-- begin:: Up Page -->
        <a
          href="javascript:void(0)"
          class="btn btn-icon btn-sm btn-light mr-2 my-1"
          @click="goToPage(meta.current_page + 1)"
          :disabled="meta.current_page == meta.last_page"
        >
          <i class="ki ki-bold-arrow-next icon-xs"></i>
        </a>
        <!-- end:: Up Page -->
        <!-- begin:: Last Page -->
        <a
          href="javascript:void(0)"
          class="btn btn-icon btn-sm btn-light mr-2 my-1"
          @click="goToPage(meta.last_page)"
          :disabled="meta.current_page == meta.last_page"
        >
          <i class="ki ki-bold-double-arrow-next icon-xs"></i>
        </a>
        <!-- end:: Last Page -->
    </div>
    <div class="d-flex align-items-center py-3">
      <div v-if="pageLoader.filtering" class="d-flex align-items-center">
        <div class="mr-2 text-muted">Loading...</div>
        <div class="spinner mr-10"></div>
      </div>
      <select
        class="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light"
        style="width: 75px;"
        v-model="selected_page_length"
        @change="changeSize()"
      >
        <template v-for="(length, i) in computedPageLengths">
          <option :key="i" :value="length">{{ length.toString() }}</option>
        </template>
      </select>
      <span v-if="selected_page_length != 'All'" class="text-muted">
        Displaying {{ (meta.to - (meta.from -1)) }} of {{ meta.total }} records
      </span>
      <span v-else class="text-muted">
        Displaying {{ meta.total }} records
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",

  props: {
    meta: { type: Object, required: true },
    pageLoader: { type: Object, required: true }
  },

  data() {
    return {
      range_size: 5,
      selected_page_length: "15"
    }
  },

  created() {
    this.selected_page_length = this.meta.per_page;
  },

  methods: {
    goToPage: function(page) {
      if (page >= 1 && page <= this.meta.last_page && !this.pageLoader.filtering) {
        this.$emit("change_page",page);
      }
    },
    changeSize: function() {
      if (!this.pageLoader.filtering) {
        this.$emit("change_size",this.selected_page_length);
      }
    }
  },

  computed: {
    computedPages: function() {
      let pages = [];
      let reversePage = null;
      let page = null;

      if (this.range_size >= 5) {
        if (this.meta.current_page == 1 || this.meta.current_page == 2) {
          page = 1;
        } else {
          page = this.meta.current_page - 2;
        }
      } else {
        page = this.meta.current_page;
      }

      while (pages.length < this.range_size && page <= this.meta.last_page) {
        if (pages[pages.length -1] != page) {
          pages.push(page);
        }
        page++;
      }

      reversePage = pages[0] -1;

      while (pages.length < this.range_size && reversePage != 0) {
        pages.unshift(reversePage);
        reversePage--;
      }

      return pages;
    },
    computedPageLengths: function() {
      return [
        '15',
        '25',
        '50',
        '100',
        'All'
      ];
    }
  }
}
</script>
