<template>
  <b-dropdown-item
    @click="deleteUsers"
  >
    {{ $t('TABLES.PROJECT_USER_NAVS.DELETE') }}
  </b-dropdown-item>
</template>

<script>
import { mapGetters } from "vuex";
import { BULK_DELETE_USERS } from "@/core/services/store/core/user.module";

export default {
  name: "DeleteUsers",

  props: {
    checkedUsers: { type: Array, required: true }
  },

  methods: {
    deleteUsers: function() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.DELETE_USER_BULK.TITLE")}`,
        text: `${this.$t("SWEETALERTS.DELETE_USER_BULK.MESSAGE")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${this.$t("SWEETALERTS.DELETE_USER_BULK.CONFIRM")}`,
        cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            users: this.checkedUsers,
            projectId: this.projectInfo.id
          };
          this.$store.dispatch(BULK_DELETE_USERS, payload).then(() => {
            this.$emit('users_changed');
            this.alertSuccess();         
          })
          .catch(() => {
            this.alertFailure();
          })
        }
      })
    },
    alertSuccess: function() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.DELETE_USER_BULK_SUCCESS.TITLE")}`,
        text: `${this.$t("SWEETALERTS.DELETE_USER_BULK_SUCCESS.MESSAGE")}`,
        icon: 'success',
        confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
      })
    },
    alertFailure: function() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.DELETE_USER_BULK_FAIL.TITLE")}`,
        text: `${this.$t("SWEETALERTS.DELETE_USER_BULK_FAIL.MESSAGE")}`,
        icon: 'error',
        confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
      })
    }
  },

  computed: {
    ...mapGetters(["projectInfo"]),
  }
};
</script>
