<template>
  <div>
    <b-form-group
      :id="id+'-group'"
      :label="computedLabel"
      :label-for="id"
      :label-sr-only="!displayLabel"
      class="w-100 px-0 mb-0"
      :class="computedLabelAlignment"
      :label-cols-sm="computedLabelColsSM"
      :label-cols-lg="computedLabelColsLG"
    >
      <treeselect
        :id="id"
        :placeholder="computedPlaceholder"
        v-model="model.$model"
        :name="id"
        :value-consists-of="defineProp('valueConsistsOf')"
        :noChildrenText="defineProp('noChildrenText')"
        :multiple="defineProp('multiple')"
        :showCount="defineProp('showCount')"
        :clearable="defineProp('clearable')"
        :searchable="defineProp('searchable')"
        :open-on-focus="defineProp('openOnFocus')"
        :clear-on-select="defineProp('clearOnSelect')"
        :close-on-select="defineProp('closeOnSelect')"
        :open-on-click="defineProp('openOnClick')"
        @input="optionSelected"
        :disabled="defineProp('disabled')"
        :options="options"
        :max-height="200"
      />
    </b-form-group>
    <div class="custom-validation-failure" v-if="model.$anyError">
      {{ error }}
    </div>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "GenericTreeSelect",

  props: {
    id: { type: String, required: true },
    model: { type: Object, required: true },
    label: { type: String, required: false },
    alignLabel: { type: String, required: false },
    placeholder: { type: String, required: false},
    error: { type: String, required: false},
    showInline: { type: Boolean, required: true },
    submissionStates: { type: Object, required: true },
    options: { type: Array, required: true },
    overrides: { type: Object, required: false }
  },

  data() {
    return {
      treeSelectOptions: {
        valueConsistsOf: 'LEAF_PRIORITY',
        noChildrenText: `${this.$t("GENERAL.FEEDBACK.NO_RESULTS")}` ,
        multiple: true,
        showCount: true,
        clearable: true,
        searchable: true,
        openOnFocus: true,
        clearOnSelect: true,
        closeOnSelect: false,
        openOnClick: false,
        disabled: false
      }
    };
  },

  components: { Treeselect },

  methods: {
    defineProp(propName) {
      if (this.overrides != undefined) {
        if (this.overrides.hasOwnProperty(propName)) {
          return this.overrides[propName];
        } else {
          return this.treeSelectOptions[propName];
        }
      }
    },
    optionSelected() {
      this.$emit('optionSelected');
    }
  },

  computed: {
    displayLabel: function() {
      if (this.label != undefined) {
        if (this.label.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    displayError: function() {
      if (this.error != undefined) {
        if (this.error.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    computedLabel: function() {
      if (this.label != undefined) {
        if (this.label.length > 0) {
          if (this.model.$params.hasOwnProperty('required')) {
            return this.label + "*";
          } else {
            return this.label;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    computedPlaceholder: function() {
      if (this.placeholder != undefined) {
        if (this.placeholder.length > 0) {
          return this.placeholder;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    computedLabelColsSM: function() {
      if (this.displayLabel) {
        if (this.showInline) {
          return 4;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    computedLabelColsLG: function() {
      if (this.displayLabel) {
        if (this.showInline) {
          return 3;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    computedLabelAlignment: function() {
      if (this.alignLabel != undefined) {
        if (this.alignLabel == "right") {
          return "text-right";
        } else if (this.alignLabel == "center") {
          return "text-center";
        } else {
          return "text-left";
        }
      } else {
        return "text-left";
      }
    }
  }
};
</script>
