<template>
  <div class="row mx-0 mb-2">
    <InputGeneric
      id="input-emal"
      class="col-4 px-2"
      :model="$v.entry.email"
      alignLabel="left"
      :placeholder="$t('FORMS.INPUTS.EMAIL')"
      :showInline="true"
      :error="$t('FORMS.ERROR.EMAIL')"
      :submissionStates="submissionStates"
      :slim="true"
      @enter_pressed="onSubmit"
      :disabled="uploadedOnce"
    >
    </InputGeneric>
    <InputGenericDropdown
      id="input-project-role"
      class="col-3 px-2"
      :model="$v.entry.system_project_role_id"
      :options="systemProjectRoles"
      optionLabel="name"
      value="id"
      :errorMessage="$t('FORMS.ERROR.PROJECT_ROLE')"
      :submissionStates="submissionStates"
      :slim="true"
    >
    </InputGenericDropdown>
    <div class="col-3 px-2">
      <InputGenericTreeSelect
        v-if="entry.system_project_role_id == 1"
        :id="'input-segment-individual-' + index"
        :model="$v.entry.project_segments"
        :placeholder="$t('SETUP.NAV.SEGMENTS')"
        :showInline="true"
        :submissionStates="submissionStates"
        :options="segmentationBreakdown"
        :overrides="{ valueFormat: 'LEAF_PRIORITY', disabled: false }"
      >
      </InputGenericTreeSelect>
    </div>
    <div class="col-2 d-flex justify-content-center align-items-center">
      <span
        v-if="!newEntry"
        class="label label-pill label-inline ml-3"
        :class="computedUploadStage.state"
        style="position:relative; top:-1px"
        v-b-tooltip.hover.left
        :title="uploadError"
      >
        {{ computedUploadStage.text }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";

import { CREATE_PROJECT_USER } from "@/core/services/store/project/project_users.module";

import InputGeneric from "@/modules/together-helpers/components/generic-inputs/GenericTextInput";
import InputGenericDropdown from "@/modules/together-helpers/components/generic-inputs/GenericDropdown";
import InputGenericTreeSelect from "@/view/components/form-inputs/GenericTreeSelect";

export default {
  mixins: [validationMixin],
  
  name: "UserImportRow",

  props: {
    submissionStates: { type: Object, required: true },
    newEntry: { type: Boolean, required: true },
    segmentationBreakdown: { type: Array, required: true },
    index: { type: Number, required: false }
  },

  data() {
    return {
      stage: 1,
      uploadedOnce: false,
      uploadError: null
    }
  },

  validations: {
    entry: {
      email: {},
      system_project_role_id: {},
      project_segments: {}
    }
  },

  components: {
    InputGeneric,
    InputGenericDropdown,
    InputGenericTreeSelect
  },

  methods: {
    onSubmit: function() {
      if (this.newEntry) {
        this.$v.entry.$touch();
        if (this.$v.entry.$anyError) {
          return;
        }

        this.$emit("user_added");
        this.$v.entry.$reset();
      }
    },
    processImport: function() {
      if (this.stage == 2 || this.stage == 3) {
        this.$emit('upload_finished');
        return;
      }

      this.stage = 2;
      this.uploadError = null;

      let newUserData = this.entry;
      newUserData.segments = newUserData.project_segments

      let payload = {
        projectId: this.projectInfo.id,
        data: newUserData
      }

      this.$store
        .dispatch(CREATE_PROJECT_USER, payload)
        .then(() => {
          this.stage = 3;
          this.uploadedOnce = true;
        })
        .catch((res) => {
          this.stage = 4;
          this.uploadError = res.data.errors.email[0];
        })
        .finally(() => {
          this.$emit('upload_finished');
        });
    }
  },

  watch:{
    computedEmail: function(newVal) {
      if (newVal.length == 0) {
        this.$emit('delete_upload',this.user.id);
      }
    },
    computedProjectRole: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.stage = 1;
      }
    },
    computedSegment: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.stage = 1;
      }
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "systemProjectRoles",
      "projectSegmentations",
      "projectSegments",
    ]),
    ...mapState({
      form: state => state.userImportWorkflow.form
    }),
    entry: function() {
      if (this.newEntry) {
        return this.form.newUser;
      } else {
        return this.form.processedUsers[this.index]
      }
    },
    computedUploadStage: function() {
      let stage = {};

      if (this.stage == 1) {
        stage = {
          state: 'label-warning',
          text: 'Pending'
        }
      } else if (this.stage == 2) {
        stage = {
          state: 'label-primary',
          text: 'Uploading'
        }
      } else if (this.stage == 3) {
        stage = {
          state: 'label-success',
          text: 'Uploaded'
        }
      } else if (this.stage == 4) {
        stage = {
          state: 'label-danger',
          text: 'Failed'
        }
      }

      return stage;
    },
    computedEmail: function() {
      return this.user.calculatedEmail;
    },
    computedProjectRole: function() {
      return this.user.system_project_role_id;
    },
    computedSegment: function() {
      return this.user.project_segment
    }
  }
};
</script>
