<template>
  <b-dropdown-item
    v-b-modal.import-modal
  >
    {{ $t('TABLES.PROJECT_USER_NAVS.ADD_PLURAL') }}
    <b-modal
      :size="modalSize"
      ref="import-modal"
      id="import-modal"
      :title="$t('TABLES.PROJECT_USER_NAVS.IMPORT')"
      :hide-footer="form.stage == 1"
    >
      <!--start::Stage 1 - User Text Input-->
      <div v-show="form.stage == 1">
        <div class="row col-xl-12 d-flex justify-content-start mb-5 px-0 mx-0">
          <InputGenericDropdown
            id="input-project-role"
            class="col-xl-12 px-0"
            :model="$v.form.batch.systemProjectRole"
            :options="systemProjectRoles"
            optionLabel="name"
            value="id"
            :errorMessage="$t('FORMS.ERROR.PROJECT_ROLE')"
          >
          </InputGenericDropdown>
        </div>
        <div class="row col-xl-12 d-flex justify-content-start mb-5 px-0 mx-0">
          <InputGenericDropdown
            id="input-project-role"
            class="col-xl-12 px-0"
            :model="$v.form.batch.systemProjectUserSample"
            :options="systemProjectUserSamples"
            optionLabel="name"
            value="id"
            :errorMessage="$t('FORMS.ERROR.REQUIRED')"
          >
          </InputGenericDropdown>
        </div>
        <div class="row col-xl-12 d-flex justify-content-start mb-5 px-0 mx-0">
          <InputGenericTreeSelect
            v-if="form.batch.systemProjectRole == 1"
            id="input-segment-bulk"
            :model="$v.form.batch.segments"
            :placeholder="$t('SETUP.NAV.SEGMENTS')"
            :showInline="true"
            :submissionStates="submissionStates"
            :options="segmentationBreakdown"
            :overrides="{ valueFormat: 'LEAF_PRIORITY', disabled: false }"
          >
          </InputGenericTreeSelect>
        </div>
        <div class="row col-xl-12 d-flex justify-content-start mb-0 px-0 mx-0">
          <InputGenericTextArea
            class="col-xl-12 px-0"
            id="input-users"
            :model="$v.form.batch.users"
            rows="8"
            :label="$t('FORMS.INPUTS.USERS')"
            displayLabel="0"
            :submissionStates="submissionStates"
            helper="Enter users to be imported here"
          >
          </InputGenericTextArea>
        </div>
        <div class="row col-xl-12 d-flex justify-content-between mb-0 px-5 mx-0">
          <div class="col-6">
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-secondary w-100"
              @click="closeModal()"
            >
              {{ $t('GENERAL.BUTTONS.CANCEL') }}
            </a>            
          </div>
          <div class="col-6">
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-primary w-100"
              @click="progressStage"
            >
              {{ progressText }}
            </a>
          </div>
        </div>
      </div>
      <!--end::Stage 1 - User Text Input-->

      <!--start::Stage 2 - Advanced Input-->
      <div v-show="form.stage == 2">
        <div class="col-xl-12 px-0 mb-2">
          <div class="table-responsive mb-0">
            <table
              id="extended_profiling_table"
              class="table table-head-custom table-vertical center table-hover mt-0"
              style="table-layout:auto"
            >
              <thead>
                <tr>
                  <th class="px-4 py-3 text-left col-4">
                    {{ $t('TABLES.USER_IMPORT.EMAIL') }}
                  </th>
                  <th class="px-4 py-3 text-left col-3">
                    {{ $t('TABLES.USER_IMPORT.PROJECT_ROLE') }}
                  </th>
                  <th class="px-2 py-3 text-left col-3">
                    {{ $t('TABLES.USER_IMPORT.SEGMENTS') }}
                  </th>
                  <th class="px-2 py-3 text-center col-2">
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <template v-for="(user, i) in form.processedUsers">
          <UserImportRow
            ref="user-upload"
            :key="i"
            :user="user"
            :index="i"
            :submissionStates="submissionStates"
            :segmentationBreakdown="segmentationBreakdown"
            :newEntry="false"
            @upload_finished="form.finishedUploads++"
            @delete_upload="removeUserUpload"
          >
          </UserImportRow>
        </template>
        <UserImportRow
          :user="form.newUser"
          :submissionStates="submissionStates"
          :segmentationBreakdown="segmentationBreakdown"
          :newEntry="true"
          @user_added="addUser"
        >
        </UserImportRow>
      </div>
      <!--end::Stage 2 - Advanced Input-->
      <template #modal-footer class="py-0">
        <div class="d-flex justify-content-between my-0 py-0">
          <a
            href="javascript:void(0)"
            class="btn btn-sm btn-secondary mr-2"
            @click="closeModal"
            :disabled="submissionStates.submitting"
          >
            {{ $t('GENERAL.BUTTONS.CANCEL') }}
          </a>
          <a
            href="javascript:void(0)"
            class="btn btn-sm btn-primary mr-2"
            @click="onSubmit"
            :disabled="submissionStates.submitting"
          >
            {{ calculatedSubmitButton }}
          </a>
        </div>
      </template>
    </b-modal>
  </b-dropdown-item>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";

import InputGenericTextArea from "@/modules/together-helpers/components/generic-inputs/GenericTextArea";
import InputGenericDropdown from "@/view/components/form-inputs/GenericDropdown";
import InputGenericTreeSelect from "@/view/components/form-inputs/GenericTreeSelect";
import UserImportRow from "./UserImportRow";

export default {
  mixins: [validationMixin],

  name: "UserImportLive",

  props: {
    segmentationBreakdown: { type: Array, required: true }
  },

  data() {
    return {
      submissionStates: {
        submitting: false
      }
    }
  },

  validations: {
    form: {
      batch: {
        systemProjectRole: {},
        systemProjectUserSample: {},
        segments: {},
        users: {}
      }
    },
  },

  components: {
    InputGenericTextArea,
    InputGenericDropdown,
    InputGenericTreeSelect,
    UserImportRow
  },

  methods: {
    progressStage: function() {
      let users = this.form.batch.users;
      users = users.replace(/,/g, '\n');
      users = users.replace(/;/g, '\n');
      users = users.replace(/ /g, '\n');
      users = users.split("\n");

      const self = this;
      let added = [];
      users.forEach(email => {
        if (this.isEmail(email) && !added.includes(email)) {
          self.form.processedUsers.push({
            id: added.length + 1,
            name: 'invited_user',
            suspended: 1,
            email: email,
            system_project_role_id: self.form.batch.systemProjectRole,
            system_project_user_sample_id: self.form.batch.systemProjectUserSample,
            project_segments: this.form.batch.segments
          });
          added.push(email);
        }
      });

      this.form.stage = 2;
    },
    addUser: function() {
      if (this.isEmail(this.form.newUser.email)) {
        const self = this;
        this.form.processedUsers = this.form.processedUsers.filter(function( user ) {
          return user.email !== self.form.newUser.email;
        });
        this.form.processedUsers.push(this.form.newUser);
        this.form.newUser = { email: "", system_project_role_id: 1, project_segments: [], name: 'invited_user' };
      }
    },
    closeModal: function() {
      this.onReset();
      this.$refs['import-modal'].hide();
    },
    onSubmit: function() {
      this.submissionStates.submitting = true;
      this.$refs['user-upload'].forEach(userImport => {
        userImport.processImport();
      });
    },
    onReset: function() {
      this.form.stage = 1;
      this.form.batch.users = "";
      this.form.batch.systemProjectRole = 1;
      this.form.batch.segments = [];
      this.form.processedUsers = [];
      this.form.finishedUploads = 0;
      this.submissionStates.submitting = false;
      this.$emit('users_changed');
    },
    isEmail: function(email) {
      /* eslint-disable */
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      /* eslint-enable */      

      return re.test(email);
    },
    removeUserUpload: function(uploadId) {
      this.form.processedUsers = this.form.processedUsers.filter(function( user ) {
        return user.id !== uploadId;
      });
    }
  },

  watch:{
    finishedUploads: function (newVal){
      if (newVal == this.form.processedUsers.length) {
        this.form.finishedUploads = 0;
        this.submissionStates.submitting = false;
      }
    }
  },

  computed: {
    ...mapGetters([
      "systemProjectRoles",
      "projectSegmentations",
      "projectSegments",
      "systemProjectUserSamples"
    ]),

    ...mapState({
      form: state => state.userImportWorkflow.form
    }),

    modalSize: function() {
      return (this.form.stage == 1) ? 'md' : 'xl';
    },

    calculatedSubmitButton: function() {
      if (this.submissionStates.submitting) {
        return `${this.$t("GENERAL.BUTTONS.IMPORTING")}`
      } else {
        return `${this.$t("GENERAL.BUTTONS.IMPORT")}`
      }
    },

    progressText: function() {
      return (this.form.batch.users.length > 0) ? `${this.$t('GENERAL.BUTTONS.NEXT')}` : `${this.$t('GENERAL.BUTTONS.SKIP')}`;
    },

    finishedUploads: function() {
      return this.form.finishedUploads;
    }
  }
}
</script>