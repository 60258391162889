<template>
  <a
    href="javascript:void(0)"
    class="btn btn-icon btn-light btn-hover-danger btn-sm mx-1"
    v-b-tooltip.hover.bottom
    :title="calculatedTooltip"
    @click="deleteProjectUser()"
  >
    <span class="svg-icon svg-icon-md svg-icon-danger">
      <inline-svg :src="calculatedIcon" />
    </span>
  </a>
</template>

<script>
import { mapGetters } from "vuex";
import { DELETE_PROJECT_USER } from "@/core/services/store/project/project_users.module";

export default {
  name: "DeleteProjectUser",

  props: {
    user: { type: Object, required: true }
  },

  methods: {
    deleteProjectUser: function() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS." + this.calculatedMessages.challenge + ".TITLE")}`,
        text: this.user.display_name + " " + `${this.$t("SWEETALERTS." + this.calculatedMessages.challenge + ".MESSAGE")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${this.$t("SWEETALERTS." + this.calculatedMessages.challenge + ".CONFIRM")}`,
        cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = { userId: this.user.id, projectId: this.projectInfo.id };
          this.$store.dispatch(DELETE_PROJECT_USER, payload).then(() => {
            this.$swal.fire({
              title: `${this.$t("SWEETALERTS." + this.calculatedMessages.success + ".TITLE")}`,
              text: this.user.display_name + ' ' + `${this.$t("SWEETALERTS." + this.calculatedMessages.success + ".MESSAGE")}`,
              icon: 'success',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })          
          })
          .catch(() => {
            this.$swal.fire({
              title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
              text: `${this.$t("SWEETALERTS." + this.calculatedMessages.fail + ".MESSAGE")}`,
              icon: 'error',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })  
          })
        }
      })
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo"
    ]),
    subscribed: function() {
      let projectRole = null;

      if (this.user.active_project_role.length > 0) {
        this.user.active_project_role.forEach(role => {
          if (role.project_profile.project_id == this.projectInfo.id) {
            projectRole = role;
          };
        });
      };

      return projectRole != null ? true : false;
    },
    calculatedIcon: function() {
      if (this.subscribed || this.user.invite_sent == 0) {
        return "media/svg/icons/Home/Trash.svg";
      } else {
        return "media/svg/icons/Navigation/Close.svg";
      }
    },
    calculatedTooltip: function() {
      if (this.subscribed || this.user.invite_sent == 0) {
        return `${this.$t("TABLES.PROJECT_USER_TOOLTIPS.DESTROY")}`;
      } else {
        return `${this.$t("TABLES.USER_TOOLTIPS.REVOKE")}`;
      }
    },
    calculatedMessages: function() {
      let messageIdentifiers = {};

      if (this.subscribed) {
        messageIdentifiers.challenge = "DELETE_PROJECT_USER_CHALLENGE";
        messageIdentifiers.success = "DELETE_PROJECT_USER_SUCCESS";
        messageIdentifiers.fail = "DELETE_PROJECT_USER_FAIL";
      } else {
        messageIdentifiers.challenge = "REVOKE_PROJECT_USER_CHALLENGE";
        messageIdentifiers.success = "REVOKE_PROJECT_USER_SUCCESS";
        messageIdentifiers.fail = "REVOKE_PROJECT_USER_FAIL";
      }

      return messageIdentifiers;
    }
  }
};
</script>
