<template>
  <b-alert
    class="my-10" 
    show 
    variant="danger"
  >
    <ul class="px-5">
      <template v-for="(error, i) in validationErrors">
        <li class="py-1" v-bind:key="i">
          {{ error.errors[0] }}
        </li>
      </template>
    </ul>
  </b-alert>
</template>

<script>
export default {
  name: "FileImportErrors",

  props: {
    validationErrors:     { type: Array, required: true }
  }
}
</script>