<template>
  <a
    href="javascript:void(0)"
    @click="applyStreamFilter({ participants: [{id: user.id, display: user.display_name}], preLoading: 1 })"
    class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
    v-b-tooltip.hover.bottom
    :title="$t('TABLES.PROJECT_USER_TOOLTIPS.POSTS')"
  >
    <span class="svg-icon svg-icon-md svg-icon-primary">
      <inline-svg
        src="media/svg/icons/Communication/Chat2.svg"
      />
    </span>
  </a>
</template>

<script>
import { POPULATE_FILTER_LIST } from "@/core/services/store/project/project_stream.module";

export default {
  name: "ViewUserPosts",

  props: {
    user: { type: Object, required: true }
  },

  methods: {
    applyStreamFilter: function(filter) {
      this.$store
        .dispatch(POPULATE_FILTER_LIST, filter)
        .then(() => {
          this.$router.push({ name: 'stream'})
        })
    } 
  },
}
</script>