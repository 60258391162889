<template>
  <div
    id="input-filter-login-choices"
    role="group"
    class="form-row form-group w-100 mb-0 text-right"
  >
    <label
      class="col-sm-4 col-lg-3 col-form-label"
    >
      {{ $t('GENERAL.TEXT.THAT') }}
    </label>
    <div class="row d-flex justify-content-start col-sm-8 col-lg-9 pl-5 filter-options-row">
      <b-button
        class="btn-sm mt-2 mr-1"
        :variant="opt1Variant"
        @click="emitChange(1)"
      >
        {{ $t('GENERAL.TEXT.HAS') }}
      </b-button>
      <b-button
        class="btn-sm mt-2 mr-1"
        :variant="opt2Variant"
        @click="emitChange(2)"
      >
        {{ $t('GENERAL.TEXT.HASNT') }}
      </b-button>
      <p class="mb-0 pt-3 font-size-sm">{{ $t('GENERAL.TEXT.LOGGED_IN') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterOptionsLogin",

  props: {
    loginFilterOption: { type: Number, required: true }
  },

  methods: {
    emitChange: function(val) {
      this.$emit('filterOptionChanged',val);
    }
  },

  computed: {
    opt1Variant: function() {
      return (this.loginFilterOption == 1) ? 'primary' : 'primary-outline';
    },
    opt2Variant: function() {
      return (this.loginFilterOption == 2) ? 'primary' : 'primary-outline';
    }
  }
}
</script>
