<template>
  <div class="table-responsive">
    <table
      id="project_users_table"
      class="table table-head-custom table-vertical center table-hover"
      style="table-layout:auto; overflow: auto"
    >
      <thead>
        <tr>
          <th class="px-2 py-3 text-center" style="width:60px">
            <b-form-checkbox id="checkAllComponent" v-model="checkAll" size="lg"></b-form-checkbox>
          </th>
          <th class="px-2 py-3" style="width:50px">
          </th>
          <th class="px-2 py-3" style="min-width:190px">
            {{ $t('TABLES.PROJECT_USER_HEADERS.USER') }}
          </th>
          <th class="px-2 py-3 text-left" style="min-width:140px">
            {{ $t('TABLES.PROJECT_USER_HEADERS.ROLE') }}
          </th>
          <th class="px-2 py-3 text-center" style="min-width:150px">
            {{ $t('TABLES.PROJECT_USER_HEADERS.SOURCE') }}
          </th>
          <th class="border-bottom-0 py-2 px-4 text-left" style="min-width:100px">
            {{ $t('TABLES.PROJECT_USER_HEADERS.LAST_LOGIN') }}
          </th>
          <th class="border-bottom-0 py-2 px-4 text-right" style="width:200px">
            {{ $t('TABLES.PROJECT_USER_HEADERS.ACTIONS') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(projectUser, i) in projectUsers">
          <ProjectUserRow
            :ref="'project-user-row-' + projectUser.id"
            :key="i"
            :projectUser="projectUser"
            :checkAll="checkAll"
            :events="events"
            @user_checked="$emit('user_checked',$event)"
            @user_unchecked="$emit('user_unchecked',$event)"
            @users_changed="$emit('users_changed')"
            @open_profile_modal="openProfileModal"
            @on-view-user-events="$emit('on-view-user-events', $event)"
          >
          </ProjectUserRow>
        </template>
        <tr v-if="projectUsers.length == 0">
          <td class="px-2 text-center" colspan="6">
            <span class="text-dark-65 font-weight-bold d-block py-3">
              {{ $t('TABLES.PROJECT_USER_MESSAGES.NO_PROJECT_USERS_TEXT') }}
              <router-link
                tag="a"
                class="text-primary"
                :to="{ name: 'add_project_user' }"
              >
                {{ $t('TABLES.PROJECT_USER_MESSAGES.NO_PROJECT_USERS_LINK') }}
              </router-link>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <UserProjectProfile
      :userId="userId"
      ref="userProjectProfileModal"
    >
    </UserProjectProfile>
  </div>
</template>

<script>
import ProjectUserRow from "@/view/content/projects/ProjectUserRow";
import UserProjectProfile from "@/view/content/modals/UserProjectProfile";

export default {
  name: "ProjectUserTable",

  props: {
    error:              { type: Object, required: true },
    pageLoader:         { type: Object, required: true },
    projectUsers:       { type: Array, required: true },
    checkedUsers:       { type: Array, required: true },
    events:             { type: Array, required: false, default: function() { return [] } }
  },

  data() {
    return {
      userId: null,
      checkAll: false
    }
  },

  components: {
    ProjectUserRow,
    UserProjectProfile
  },

  methods: {
    checkUsers: function(users,checkedState) {
      const self = this;
      users.forEach(function (userId) {
        self.$refs[`project-user-row-${userId}`][0].check(checkedState);
      });
    },
    openProfileModal: function(val) {
      this.userId = val;
      setTimeout(() => {
        this.$refs.userProjectProfileModal.$refs.modal.show();
      }, 100);
    }
  },

  watch: {
    pageLoader: {
      handler() {
        this.checkAll = false;
        this.$emit('all_unchecked');
      },
      deep: true
    }
  }
}
</script>
