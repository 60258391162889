<template>
  <form
    class="d-flex justify-content-between align-items-center flex-wrap text-center"
  >
    <label class="col-2 text-left mb-0">{{ $t('TABLES.PROJECT_USER_UPLOAD.SELECT_FILE') }}</label>

    <div class="input-group mb-0 col-8 px-20" style="height:32.5px">
      <div class="custom-file">
        <input
          id="userImportFileInput"
          type="file"
          class="custom-file-input"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          value="test"
          :disabled="submitting == true || importing == true"
          @change="onValidateUpload"
        >
        <label
          for="fileInput"
          class="custom-file-label"
          style="height: 32.5px; padding: 6px 15px"
        >
          {{ (file == null) ? 'Select File...' : file.name }}
        </label>
      </div>
    </div>
    
    <div class="col-2 text-right" style="height:32.5px">
      <button
        type="button"
        class="btn btn-sm btn-primary"
        @click="onValidationSubmit"
        :disabled="file == null || submitting == true || importing == true"
      >
        {{ $t('TABLES.PROJECT_USER_UPLOAD.VALIDATE') }}
      </button>
    </div>
    <div class="custom-validation-failure" style="height:12.5px">
      <span v-if="error">{{ error }}</span>
    </div>
  </form>

</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";

export default {
  name: "FileImportForm",

  props: {
    file:       { required: false },
    submitting: { type: Boolean, required: true },
    importing:  { type: Boolean, required: true }
  },

  methods: {
    onValidationSubmit: function() {
      if (this.file == null) {
        return;
      } else {
        this.$emit('on-validation-response-change', null);
        this.$emit('on-validation-errors-change', null);
        this.$emit('on-validation-errors-count-change', null);
        this.$emit('on-submit-change', true);
      }

      let data = new FormData();
      data.append("file", this.file);

      let payload = {
        projectId: this.projectInfo.id,
        data: data
      }

      this.uploadProjectUsers(payload).then((res) => {
        this.$emit('on-validation-response-change', res.data);
        this.$emit('on-validation-errors-change', res.critical_errors);
        this.$emit('on-validation-errors-count-change', res.non_critical_errors);
      }).finally(() => {
        this.$emit('on-submit-change', false);
      })
    },
    onValidateUpload: function(event) {
      if (event.target.files.length > 0) {
        let file = event.target.files[0];
        let csvFile = file.name.endsWith(".csv")
        if (![
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel'
        ].includes(file.type) || csvFile) {
          this.error = `${this.$t('TABLES.PROJECT_USER_UPLOAD.INVALID_FILE_TYPE')}`;
          return;
        } else {
          this.error = false;
          this.$emit('on-file-change',file);
        }
      }
    },
    ...mapActions({
      uploadProjectUsers: "uploadProjectUsers"
    })
  },

  data() {
    return {
      error : false
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo"
    ])
  }
}
</script>