<template>
  <div class="row d-inline mx-0">
    <div class="card card-custom gutter-b">
      <div class="card-body p-4">
        <div class="d-flex justify-content-center mx-0" v-if="!loadingComplete && !error.active">
          <b-spinner
            variant="primary"
            label="Spinning"
            class="table-loader my-3"
          >
          </b-spinner>
        </div>
        <div v-if="loadingComplete">
          <div class="d-flex justify-content-start mb-5">
            <h6 class="my-1 py-2">{{ $t('GENERAL.PROJECT_USER_FILTER.TITLE') }}</h6>
          </div>
          <!-- start::Name Filter -->
          <div class="row px-5 py-2">
            <InputGeneric
              id="input-filter-name"
              :model="$v.filter.x_filter_name"
              :label="$t('GENERAL.PROJECT_USER_FILTER.NAME_LABEL') + ':'"
              alignLabel="right"
              :placeholder="$t('GENERAL.PROJECT_USER_FILTER.NAME_PLACEHOLDER')"
              :showInline="true"
              :submissionStates="submissionStates"
            >
            </InputGeneric>
          </div>
          <!-- end::Name Filter -->
          <!-- start::Role Filter -->
          <div class="row px-5 py-2">
            <InputGenericTreeSelect
              id="input-filter-roles"
              :model="$v.filter.x_filter_roles"
              :label="$t('GENERAL.PROJECT_USER_FILTER.ROLE_LABEL') + ':'"
              alignLabel="right"
              :placeholder="$t('GENERAL.PROJECT_USER_FILTER.ROLE_PLACEHOLDER')"
              :showInline="true"
              :submissionStates="submissionStates"
              :options="systemProjectRoles"
              :overrides="{ valueFormat: 'BRANCH_PRIORITY' }"
            >
            </InputGenericTreeSelect>
          </div>
          <!-- end::Role Filter -->
          <!-- start::Status Filter -->
          <div class="row px-5 py-2">
            <InputGenericTreeSelect
              id="input-filter-status"
              :model="$v.filter.x_filter_status"
              :label="$t('GENERAL.PROJECT_USER_FILTER.STATUS_LABEL') + ':'"
              alignLabel="right"
              :placeholder="$t('GENERAL.PROJECT_USER_FILTER.STATUS_PLACEHOLDER')"
              :showInline="true"
              :submissionStates="submissionStates"
              :options="statusOptions"
              :overrides="{ valueFormat: 'BRANCH_PRIORITY' }"
            >
            </InputGenericTreeSelect>
          </div>
          <!-- end::Status Filter -->
          <!-- start::Source Filter -->
          <div class="row px-5 py-2">
            <InputGenericTreeSelect
              id="input-filter-source"
              :model="$v.filter.x_filter_sources"
              :label="$t('GENERAL.PROJECT_USER_FILTER.SOURCE_LABEL') + ':'"
              alignLabel="right"
              :placeholder="$t('GENERAL.PROJECT_USER_FILTER.SOURCE_PLACEHOLDER')"
              :showInline="true"
              :submissionStates="submissionStates"
              :options="systemProjectUserSamples"
              :overrides="{ valueFormat: 'BRANCH_PRIORITY' }"
            >
            </InputGenericTreeSelect>
          </div>
          <!-- end::Source Filter -->
          <!-- start::Login Filter -->
          <div class="row px-5 pt-2">
            <FilterOptionsLogin
              :loginFilterOption="filter.x_filter_loginFilterOption"
              @filterOptionChanged="filter.x_filter_loginFilterOption = $event"
            >
            </FilterOptionsLogin>
          </div>
          <div class="row px-5 pb-2">
            <InputGenericDateRangePicker
              id="input-filter-name"
              :model="$v.filter.x_filter_loginDates"
              :label="' '"
              alignLabel="right"
              :placeholder="$t('GENERAL_WIDGETS.PROJECT_HEALTH.FILTER_OPTION_3')"
              :showInline="true"
              :submissionStates="submissionStates"
              @selection_cleared="filter.x_filter_loginDates = { startDate: null, endDate: null }"
              @update="dateRangeUpdated($event)"
              :clearable="true"
              :minDate="projectInfo.starts_on"
              :maxDate="projectInfo.ends_on"
            >
            </InputGenericDateRangePicker>
          </div>
          <!-- end::Login Filter -->
          <!-- start::Segment Filter -->
          <div class="row px-5 pt-2">
            <FilterOptionsSegment
              :segmentFilterOption="filter.x_filter_segmentFilterOption"
              @filterOptionChanged="changeSegmentFilterOption"
            >
            </FilterOptionsSegment>
          </div>
          <div class="row px-5 pb-2">
            <InputGenericTreeSelect
              id="input-filter-segments"
              :model="$v.filter.x_filter_segments"
              :label="' '"
              alignLabel="right"
              :placeholder="$t('SETUP.NAV.SEGMENTS')"
              :showInline="true"
              :submissionStates="submissionStates"
              :options="segmentationBreakdown"
              :overrides="{ valueFormat: 'LEAF_PRIORITY', disabled: segmentSelectDisabled }"
            >
            </InputGenericTreeSelect>
          </div>
          <!-- end::Segment Filter -->
          <!-- start:Filter Actions -->
          <div class="d-flex justify-content-end my-5">
            <button
              type="button"
              class="btn btn-secondary px-7"
              :disabled="pageLoader.filtering"
              @click="$emit('on-filter-reset')"
            >
              {{ $t('REVIEW.TEXT_ANALYSIS.RESET') }}
            </button>
            <button
              type="button"
              class="btn btn-primary px-7 ml-3"
              :disabled="pageLoader.filtering"
              @click="$emit('on-filter-apply')"
            >
              {{ $t('REVIEW.TEXT_ANALYSIS.APPLY') }}
            </button>
          </div>
          <!-- end::Filter Actions -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";

import InputGeneric from "@/view/components/form-inputs/GenericInput";
import InputGenericTreeSelect from "@/view/components/form-inputs/GenericTreeSelect";
import FilterOptionsSegment from "@/view/components/form-inputs/FilterOptionsSegments";
import FilterOptionsLogin from "@/view/components/form-inputs/FilterOptionsLogin";
import InputGenericDateRangePicker from "@/modules/together-helpers/components/generic-inputs/GenericDateRangePicker"

export default {
  mixins: [validationMixin],

  name: "ProjectUserFilter",

  props: [
    "error",
    "pageLoader",
    "systemProjectRoles",
    "segmentationBreakdown",
    "projectInfo",
    "filter"
  ],

  data() {
    return {
      submissionStates: {
        submitting: false
      },
      segmentSelectDisabled: false,
      statusOptions: [
        {id: 4, label: `${this.$t("SYSTEM.PROFILE_STATE.4")}`},
        {id: 2, label: `${this.$t("SYSTEM.PROFILE_STATE.2")}`},
        {id: 1, label: `${this.$t("SYSTEM.PROFILE_STATE.1")}`},
        {id: 5, label: `${this.$t("TABLES.PROJECT_USER_TOOLTIPS.PROJECT_BLOCKED")}`},
        {id: 3, label: `${this.$t("GENERAL.STATUS.SYSTEM_BLOCKED")}`}
      ]
    }
  },

  created() {
    if(this.$route.params.segment != undefined) {
      if (this.$route.params.segment != 'no_segment') {
        this.changeSegmentFilterOption(1);
        this.filter.x_filter_segments = [this.$route.params.segment];
      } else {
        this.changeSegmentFilterOption(4);
      }
    }

    if (this.$route.params.status != undefined) {
      if (this.$route.params.status == 'subscribed') {
        this.filter.x_filter_status = ['1'];
      } else if (this.$route.params.status == "invited") {
        this.filter.x_filter_status = ['2'];
      }
    }
  },

  components: {
    InputGeneric,
    InputGenericTreeSelect,
    FilterOptionsSegment,
    FilterOptionsLogin,
    InputGenericDateRangePicker
  },

  validations: {
    filter: {
      x_filter_name: {},
      x_filter_roles: {},
      x_filter_status: {},
      x_filter_sources: {},
      x_filter_segments: {},
      x_filter_loginDates: {}
    }
  },

  methods: {
    changeSegmentFilterOption: function(value) {
      this.filter.x_filter_segmentFilterOption = value;
      if (value == 4) {
        this.segmentSelectDisabled = true;
        this.filter.x_filter_segments = [];
      } else {
        this.segmentSelectDisabled = false;
      }
    },
    dateRangeUpdated(event) {
      this.filter.x_filter_loginDates = event.utcDates
    }
  },

  computed: {
    ...mapGetters([
      "systemProjectUserSamples"
    ]),
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>
