<template>
  <b-dropdown-item
    v-b-modal.import-modal-file
  >
    {{ $t('TABLES.PROJECT_USER_NAVS.UPLOAD') }}
    <b-modal
      size="xl"
      ref="import-modal-file"
      id="import-modal-file"
      hide-footer
    >
      <template #modal-header>
        <h5 class="mb-0">{{ $t('TABLES.PROJECT_USER_NAVS.UPLOAD') }}</h5>

        <a href="/files/UserUploadExample.xlsx" download>{{ $t('TABLES.PROJECT_USER_UPLOAD.DOWNLOAD_TEMPLATE') }}</a>
      </template>

      <div
        class="container mt-5 user-file-upload"
        :class="{ 'mb-5' : validationResponse == null }"
      >
        <FileImportForm
          :file="file"
          :submitting="submitting"
          :importing="importing"
          @on-validation-response-change="validationResponse = $event"
          @on-validation-errors-change="validationErrors = $event"
          @on-validation-errors-count-change="nonCriticalErrorCount = $event"
          @on-submit-change="submitting = $event"
          @on-file-change="file = $event"
        />

        <ErrorImportForm
          v-if="criticalErrorPresent"
          :criticalErrorPresent="criticalErrorPresent"
          :validationErrors="validationErrors"
        />

        <ValidateImportSummary
          v-if="!importing"
          :submitting="submitting"
          :validationResponse="validationResponse"
          :criticalErrorPresent="criticalErrorPresent"
          :nonCriticalErrorCount="nonCriticalErrorCount"
          @on-import-change="importing = $event"
          @on-reset="closeModal()"
        />

        <UpdateImportSummary
          v-else
          :validationResponse="validationResponse"
          @on-validation-response-change="validationResponse = $event"
          @on-close="closeModal()"
        />
      </div>
    </b-modal>
  </b-dropdown-item>
</template>

<script>
import {
  mapGetters
} from "vuex";

import FileImportForm from "./file_import/Form";
import ErrorImportForm from "./file_import/Errors";
import ValidateImportSummary from "./file_import/Validate";
import UpdateImportSummary from "./file_import/Update";

export default {
  name: "UserImportFile",

  data() {
    return {
      error: null,
      submitting: false,
      file: null,
      validationResponse: null,
      validationErrors: null,
      nonCriticalErrorCount: null,
      importing: false
    }
  },

  components: {
    FileImportForm,
    ErrorImportForm,
    ValidateImportSummary,
    UpdateImportSummary
  },

  methods: {
    closeModal: function() {
      this.error = null;
      this.submitting = false;
      this.file = null;
      this.validationResponse = null;
      this.validationErrors = null;
      this.nonCriticalErrorCount = null;
      this.importing = null;
      
      this.$refs['import-modal-file'].hide()
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo"
    ]),
    criticalErrorPresent: function() {
      return (this.validationErrors != null && this.validationErrors.length > 0) ? true : false;
    }
  }
}
</script>

<style scoped>
  .custom-file label::after {
    padding: 6px 15px !important;
  }
</style>